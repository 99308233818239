module.exports =
(function ($) {
    "use strict";
    function extend(obj) {

        obj.moveToInternal = function (index) {
            var straight = this.carousel.options.selectedIndex - index;
            var reverse = 0;
            var reversePreferable = false;
            if (index > this.carousel.options.selectedIndex) {
                reverse = this.carousel.elements.length - index + this.carousel.options.selectedIndex;
            }
            else {
                reverse = (this.carousel.elements.length - this.carousel.options.selectedIndex + index) * -1;
                reversePreferable = true;
            }

            var distance = straight * this.getActualDistance();
            if (Math.abs(reverse) < Math.abs(straight) || (reversePreferable && Math.abs(reverse) == Math.abs(straight)))
                distance = reverse * this.getActualDistance();
        
            this.carousel.inputController.nonInterruptibleMode(true);
            this.carousel._raiseMotionStart();

            this.carousel.animation.animate(0, distance, index, Math.abs(reverse) === 1 ? null : "linear");
        };

        obj.moveBack = function () {
            var pendingTarget = this.carousel.animation.isInProgress ? this.carousel.animation.getTargetValue() : this.carousel.options.selectedIndex;

            pendingTarget--;

            if (pendingTarget < 0)
                pendingTarget = this.carousel.elements.length + pendingTarget;

            this.carousel.inputController.nonInterruptibleMode(true);
            this.carousel._raiseMotionStart();
            this.carousel.animation.animate(0, this.getActualDistance(), pendingTarget, null);
            return true;
        };

        obj.moveForward = function () {
            var pendingTarget = this.carousel.animation.isInProgress ? this.carousel.animation.getTargetValue() : this.carousel.options.selectedIndex;

            pendingTarget++;
            if (pendingTarget >= this.carousel.elements.length)
                pendingTarget -= this.carousel.elements.length;

            this.carousel.inputController.nonInterruptibleMode(true);
            this.carousel._raiseMotionStart();
            this.carousel.animation.animate(0, -1 * this.getActualDistance(), pendingTarget, null);
            return true;
        };

        obj.consumeMotion = function (distance) {
            var highFrictionRange = this.carousel._alignElements(distance);

            var scrolledElements = parseInt(distance / this.getActualDistance(), 10);

            var prevIndex = this.carousel.options.selectedIndex;
            this.carousel.options.selectedIndex -= scrolledElements;

            var consumedDistance = prevIndex - this.carousel.options.selectedIndex;
            if (this.carousel.options.selectedIndex < 0) {
                this.carousel.options.selectedIndex = this.carousel.options.selectedIndex % this.carousel.elements.length + this.carousel.elements.length;
                consumedDistance = this.carousel.elements.length - this.carousel.options.selectedIndex + prevIndex;
            }
            if (this.carousel.options.selectedIndex >= this.carousel.elements.length) {
                this.carousel.options.selectedIndex = this.carousel.options.selectedIndex % this.carousel.elements.length;
                consumedDistance = this.carousel.elements.length - prevIndex + this.carousel.options.selectedIndex;
                consumedDistance *= -1;
            }
        
            if (prevIndex != this.carousel.options.selectedIndex)
                this.carousel._raiseChangeEvent();

            return { distance: consumedDistance * this.getActualDistance(), highFrictionRange: highFrictionRange };
        };

        obj.handleMotionEnd = function (remainingDistance) {
            if (remainingDistance == 0)
                return;

            var targetIndex = this.carousel.options.selectedIndex;

            if (Math.abs(remainingDistance) > this.getActualDistance() / 2) {
                if (remainingDistance < 0)
                    targetIndex++;
                else
                    targetIndex--;
            }

            var reverse = false;
            if (this.carousel.elements.length == 0)
                targetIndex = 0;
            else {
                if (targetIndex < 0) {
                    targetIndex = targetIndex % this.carousel.elements.length + this.carousel.elements.length;
                    reverse = 'back';
                }
                if (targetIndex >= this.carousel.elements.length) {
                    targetIndex = targetIndex % this.carousel.elements.length;
                    reverse = 'forward';
                }
            }

            var targetDistance = 0;
            if (!reverse) {
                targetDistance = (this.carousel.options.selectedIndex - targetIndex) * this.getActualDistance();
            }
            else {
                if(reverse === 'back')
                    targetDistance = (this.carousel.elements.length - targetIndex + this.carousel.options.selectedIndex) * this.getActualDistance();
                else
                    targetDistance = (this.carousel.elements.length - this.carousel.options.selectedIndex + targetIndex) * this.getActualDistance() * -1;
            }
        
            var duration = Math.abs(this.carousel.options.rotationAnimationDuration * (remainingDistance / this.getActualDistance()));
            duration = Math.min(duration, this.carousel.options.rotationAnimationDuration / 2);

            this.carousel.animation.animate(remainingDistance, targetDistance, targetIndex, null, duration);
        };

        obj.alignElements = function (animationShift) {

            if (this.carousel.elements.length == 0 || this.carousel.options.selectedIndex < 0)
                return false;

            this.carousel.containerSize = this.carousel._getContainerSize();

            var shift = 0;
            if (typeof (animationShift) != "undefined")
                shift = animationShift;

            var location = this.getRootValue();
            var ranges = this.getFadeRanges(location);

            for (var i = 0; i < this.carousel.elements.length; i++) {
                this.carousel.elements[i].isEndlessProcessed = false;
            }

            var visibilityInfo = [];

            for (var i = new endlessIterator(this.carousel, this.carousel.options.selectedIndex) ; !i.isCycleCompleted(); i.moveNext()) {
                var visible = this.setElementPosition(this.carousel.elements[i.getCurrentIndex()], location + shift, ranges);
                if (visible)
                    this.carousel.elements[i.getCurrentIndex()].isEndlessProcessed = true;
                visibilityInfo[i.getCurrentIndex()] = visible;
                location = this.incrementValue(location, this.getActualDistance());
            }

            location = this.getRootValue();

            for (var i = new endlessIterator(this.carousel, this.carousel.options.selectedIndex - 1) ; !i.isCycleCompleted() ; i.movePrev()) {
                if (this.carousel.elements[i.getCurrentIndex()].isEndlessProcessed)
                    break;

                location = this.decrementValue(location, this.getActualDistance());
                visibilityInfo[i.getCurrentIndex()] = this.setElementPosition(this.carousel.elements[i.getCurrentIndex()], location + shift, ranges);
            }

            for (var i = 0; i < this.carousel.elements.length; i++) {
                this.base.setElementVisibilityInternal(!visibilityInfo[i], this.carousel.elements[i]);
            }

            this.setZIndexes();

            return false;
        };

        obj.setElementVisibilityInternal = function (hide) { };
    };

    var endlessIterator = function (carousel, currentIndex) {
        this.currentIndex = currentIndex;
        if (this.currentIndex == -1)
            this.currentIndex = carousel.elements.length - 1;
        this.iterations = 0;
        this.carousel = carousel;

        this.moveNext = function () {
            this.currentIndex++;
            if (this.currentIndex == this.carousel.elements.length)
                this.currentIndex = 0;
            this.iterations++;
        };

        this.movePrev = function () {
            this.currentIndex--;
            if (this.currentIndex == -1)
                this.currentIndex = this.carousel.elements.length - 1;
            this.iterations--;
        };

        this.isCycleCompleted = function () {
            return Math.abs(this.iterations) >= this.carousel.elements.length;
        };

        this.getCurrentIndex = function () {
            return this.currentIndex;
        };

    };

    return function (carousel) {

        var rotation_logic_controller = require('./rotation_logic_controller.js');
        this.base = new rotation_logic_controller(carousel);
        $.extend(this, this.base);

        extend(this);
    };

})(jQuery)
;