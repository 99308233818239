module.exports =
    (function ($) {
        "use strict";
        return function (carousel, settings) {
            var rotation = require('./../rotation.js');
            var vector = require('./../vector.js');

            this.settings = {
                shiftX: 0,
                shiftY: 0,
                shiftZ: 0,
                rotationAngleXY: 0,
                rotationAngleZY: 0,
                rotationAngleZX: 0,
                rotateElements: false,
                endless: false
            };

            this.carousel = carousel;
            $.extend(this.settings, settings);


            this.getContainerSize = function () {
                return this.carousel._getContainerSize();
            };

            this.isEndless = function () {
                return this.settings.endless;
            };

            this.minValue = function () {
                return null;
            };

            this.maxValue = function () {
                return null;
            };

            this.rootValue = function () {
                return 0;
            };

            this.incrementValue = function (value, distance) {
                return value + distance;
            };

            this.decrementValue = function (value, distance) {
                return value - distance;
            };

            this.getPoint = function (value) {

                var res = this.getPointInternal(value);

                res.x = res.x + this.settings.shiftX;
                res.y = res.y + this.settings.shiftY;
                res.z = res.z + this.settings.shiftZ;

                var pair = null;

                pair = this.rotate({ a: res.x, b: res.y }, { a: 0, b: 0 }, this.settings.rotationAngleXY);
                res.x = pair.a;
                res.y = pair.b;

                pair = this.rotate({ a: res.z, b: res.y }, { a: 0, b: 0 }, this.settings.rotationAngleZY);
                res.z = pair.a;
                res.y = pair.b;

                pair = this.rotate({ a: res.z, b: res.x }, { a: 0, b: 0 }, this.settings.rotationAngleZX);
                res.z = pair.a;
                res.x = pair.b;

                return res;
            };

            this.rotate = function (pairToRotate, pairCenter, angle) {
                if (angle == 0)
                    return pairToRotate;

                var angleInRadians = angle * (Math.PI / 180);
                var cosTheta = Math.cos(angleInRadians);
                var sinTheta = Math.sin(angleInRadians);

                var a = (cosTheta * (pairToRotate.a - pairCenter.a) - sinTheta * (pairToRotate.b - pairCenter.b) + pairCenter.a);
                var b = (sinTheta * (pairToRotate.a - pairCenter.a) + cosTheta * (pairToRotate.b - pairCenter.b) + pairCenter.b);
                pairToRotate.a = a;
                pairToRotate.b = b;

                return pairToRotate;
            };

            this.elementsRotation = function () {
                if (!this.settings.rotateElements)
                    return null;

                if (this.settings.rotationAngleZY == 0 && this.settings.rotationAngleZX == 0 && this.settings.rotationAngleXY == 0)
                    return null;
        
                var v = new vector();
                v.x = this.settings.rotationAngleZY;
                v.y = -1 * this.settings.rotationAngleZX;
                v.z = -1 * this.settings.rotationAngleXY;

                var angle = -1 * v.length();

                return new rotation(v, angle);
            };
    };

}) (jQuery);