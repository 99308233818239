module.exports =
(function ($) {
    "use strict";

    var utils = function () {
        this.getObjectPropertyValue = function (obj, property) {
            var parts = property.split('.');
            var res = obj;
            for (var i = 0; i < parts.length; i++) {
                res = res[parts[i]];
            }

            return res;
        };

        this.setObjectPropertyValue = function (obj, property, value) {
            var parts = property.split('.');
            var target = obj;
            for (var i = 0; i < parts.length - 1; i++) {
                target = target[parts[i]];
            }

            target[parts[parts.length - 1]] = value;
        };

        this.isFF = function() {
            return navigator.userAgent.indexOf("Firefox") != -1;
        }
    };

    return new utils();


})(jQuery);