module.exports =
(function ($) {
    "use strict";
    return function (carousel, settings) {

        var effect_base = require('./effect_base.js');
        var vector = require('./../vector.js');
        var rotation = require('./../rotation.js');

        var base = new effect_base(carousel, {
        
        });

        this.xDirection = new vector();
        this.xDirection.x = 1;

        this.yDirection = new vector();
        this.yDirection.y = 1;
    
        $.extend(this, base);
        $.extend(this.settings, settings);

        this.apply = function (elementTransform, element, value) {

            var currentPoint = this.carousel.path.getPoint(value);
            var directionPoint = this.carousel.path.getPoint(value + 0.1);
        
            var direction = new vector();
            direction.initFromPoints(currentPoint, directionPoint);
            var angle = direction.angle(this.xDirection);

            angle *= this.carousel.options.allignElementsWithPathCoefficient;

            elementTransform.rotations.push(new rotation(this.yDirection, angle));
        };
    };

})(jQuery);