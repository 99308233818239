module.exports =
(function ($) {
    "use strict";
    return function (carousel, settings) {

        var effect_base = require('./effect_base.js');

        var base = new effect_base(carousel, {

        });

        $.extend(this, base);
        $.extend(this.settings, settings);

        this.apply = function (elementTransform, element, value) {
        
            if(element.isReflectionApplied)
                return;

            element.isReflectionApplied = true;
        
            element.$element.css("box-reflect", "below " + this.carousel.options.reflectionBelow +
                "px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(" +
                    (1 - this.carousel.options.reflectionHeight) + ", transparent), to(black))");
        };

        this.revert = function () {
            for (var i = 0; i < this.carousel.elements.length; i++) {
                this.carousel.elements[i].isReflectionApplied = false;
                this.carousel.elements[i].$element.css("box-reflect", '');
            }
        };
    };

})(jQuery);
