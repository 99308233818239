module.exports =
(function ($) {
    "use strict";
    return function () {

        this.x = 0;
        this.y = 0;
        this.z = 0;

        this.initFromPoints = function (p1, p2) {
            this.x = p2.x - p1.x;
            this.y = p2.y - p1.y;
            this.z = p2.z - p1.z;
        };

        this.normalize = function () {
            var length = this.length();
            this.x /= length;
            this.y /= length;
            this.z /= length;
        };

        this.length = function () {
            return Math.sqrt((this.x * this.x) + (this.y * this.y) + (this.z * this.z));
        };

        this.angle = function (v) {

            var scalarMultiplication = this.x * v.x + this.y * v.y + this.z * v.z;
            var absThis = this.length();
            var absV = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);

            var cosA = scalarMultiplication / (absThis * absV);

            var a = Math.acos(cosA);

            if (this.z > v.z)
                a *= -1;

            return a * 180 / Math.PI;
        };

        this.perpendicularX = function () {
            var res = new vector();
            res.y = this.y;
            res.z = this.z;
            res.x = -1 * (this.y * res.y + this.z * res.z) / this.x;
            return res;
        };
    };

})(jQuery);