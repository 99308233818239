
module.exports =
(function ($) {
    "use strict";
    return function (carousel) {
        extend(this);

        this.carousel = carousel;
        this.lastKeyDownTime = new Date();
        this.lastProcessedEvent = null;
        this.nonInterruptible = false;
        this.destroyables = [];

        this.carousel.widget().keyup(this.destroyable("keyup", $.proxy(this.onKeyUp, this)));
        this.carousel.widget().keydown(this.destroyable("keydown", $.proxy(this.onKeyDown, this)));

        this.carousel.widget().mousedown(this.destroyable("mousedown", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            this.carousel.widget().focus();
            this.motionStarted(this.isVerticalRotation() ? e.pageY : e.pageX);
            e.preventDefault();
        }, this)));
        this.carousel.widget().mousemove(this.destroyable("mousemove", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            if (this.canProcessEvent()) {
                this.registerEventAsProcessed();
                this.motionContinued(this.isVerticalRotation() ? e.pageY : e.pageX);
            }
            e.preventDefault();
        }, this)));
        this.carousel.widget().mouseleave(this.destroyable("mouseleave", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            if (this.nonInterruptible)
                return;
            this.carousel.motionController.motionEnded(true);
            e.preventDefault();
        }, this)));
        this.carousel.widget().mouseup(this.destroyable("mouseup", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            this.carousel.motionController.motionEnded(true);
            e.preventDefault();
        }, this)));

        if (typeof (this.carousel.widget().get(0).onmousewheel) != "undefined") {
            this.carousel.widget().on("mousewheel", this.destroyable("mousewheel", $.proxy(this.onMousewheel, this)));
        } else {
            this.carousel.widget().on("DOMMouseScroll", this.destroyable("DOMMouseScroll", $.proxy(this.onMousewheel, this)));
        }

        this.carousel.widget().on('touchstart', this.destroyable("touchstart", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            this.motionStarted(this.isVerticalRotation() ? e.originalEvent.touches[0].screenY : e.originalEvent.touches[0].screenX);
        }, this)));
        this.carousel.widget().on('touchmove', this.destroyable("touchmove", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            if (this.canProcessEvent()) {
                this.registerEventAsProcessed();
                this.motionContinued(this.isVerticalRotation() ? e.originalEvent.touches[0].screenY : e.originalEvent.touches[0].screenX);
            }
            e.preventDefault();
        }, this)));
        this.carousel.widget().on('touchend', this.destroyable("touchend", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            this.carousel.motionController.motionEnded(true);
        }, this)));
        this.carousel.widget().on('touchcancel', this.destroyable("touchcancel", $.proxy(function (e) {
            if (this.isReadonly() || !this.getGesturesEnabled())
                return;

            this.carousel.motionController.motionEnded(true);
        }, this)));
        this.carousel.widget().on('taphold', this.destroyable("taphold", function (e) { e.preventDefault(); }));
    };

    function extend(obj) {

        obj.isVerticalRotation = function() {
            return this.carousel.options.verticalRotation && !this.carousel.rotationLogicController.isFallbackMode();
        };

        obj.getSensitivity = function () {

            if (this.carousel.rotationLogicController.isFallbackMode())
                return 1;
            return this.carousel.options.sensitivity / this.carousel.fluidLayout.getAppliedScale();
        };

        obj.isReadonly = function() {
            return !this.carousel.options.enabled ||
                this.carousel.options.autorotation;
        };

        obj.getGesturesEnabled = function () {
            return this.carousel.options.gesturesEnabled;
        };

        obj.nonInterruptibleMode = function (nonInterruptible) {
            this.nonInterruptible = nonInterruptible;
        };

        obj.canProcessEvent = function () {
            if (this.lastProcessedEvent == null)
                return true;

            if (new Date() - this.lastProcessedEvent > 50)
                return true;

            return false;
        };

        obj.registerEventAsProcessed = function () {
            this.lastProcessedEvent = new Date();
        };

        obj.motionStarted = function (x) {
            this.carousel.motionController.motionStarted(x);
            this.initialX = x;
        };

        obj.motionContinued = function (x) {
            var delta = this.initialX - x;
            delta *= this.getSensitivity();
            x = this.initialX - delta;

            this.carousel.motionController.motionContinued(x);
        };

        obj.onMousewheel = function (event) {
            if (!this.carousel.options.mousewheelEnabled)
                return;

            if (this.isReadonly())
                return;

            event.preventDefault();

            if (this.carousel.getIsInMotion())
                return;

            var up = false;
            var down = false;
            var original = event.originalEvent;

        
            // jquery mousewheel plugin
            if (event.deltaY) {
                if (event.deltaY == 1)
                    up = true;
                else
                    if (event.deltaY == -1)
                        down = true;
            }

            if (original.wheelDelta) {
                if (original.wheelDelta >= 120) {
                    up = true;
                }
                else {
                    if (original.wheelDelta <= -120) {
                        down = true;
                    }
                }
            }

            if (original.detail) {
                if (original.detail == -3)
                    up = true;
                else
                    if (original.detail == 3)
                        down = true;
            }
        
            if (up) {
                if (this.getSensitivity() > 0)
                    this.carousel.moveBack();
                if (this.getSensitivity() < 0)
                    this.carousel.moveForward();
            }
            if (down) {
                if (this.getSensitivity() < 0)
                    this.carousel.moveBack();
                if (this.getSensitivity() > 0)
                    this.carousel.moveForward();
            }
        };

        obj.onKeyDown = function (event) {
            if (!this.carousel.options.keyboardEnabled)
                return;

            if (this.isReadonly())
                return;

            if (event.which == this.getBackKey() || event.which == this.getForwardKey())
                event.preventDefault();

            if (this.carousel.motionController.isInMotion || this.carousel.motionController.inertia.isInProgress)
                return;

            if ((new Date() - this.lastKeyDownTime) < this.carousel.options.minKeyDownFrequency)
                return;

            this.lastKeyDownTime = new Date();

            if (event.which == this.getBackKey()) {
                if (this.getSensitivity() > 0)
                    this.carousel.moveBack();
                if (this.getSensitivity() < 0)
                    this.carousel.moveForward();
            }
            if (event.which == this.getForwardKey()) {
                if (this.getSensitivity() < 0)
                    this.carousel.moveBack();
                if (this.getSensitivity() > 0)
                    this.carousel.moveForward();
            }
        };

        obj.onKeyUp = function (event) {
            if (this.isReadonly())
                return;

            if (this.carousel.motionController.isInMotion || this.carousel.motionController.inertia.isInProgress)
                return;

            if (event.which == this.getBackKey() || event.which == this.getForwardKey()) {
                this.carousel.animation.clearQueue();
            }
        };

        obj.destroyable = function (key, func) {
            this.destroyables[key] = func;
            return func;
        };

        obj.destroy = function () {
            for (var key in this.destroyables) {
                this.carousel.widget().off(key, this.destroyables[key]);
            }
        };

        obj.getForwardKey = function() {
            if (this.carousel.options.verticalRotation)
                return 40; //down
            return 37; //left
        };

        obj.getBackKey = function () {
            if (this.carousel.options.verticalRotation)
                return 38; //up
            return 39; //right
        };
    }


})(jQuery);