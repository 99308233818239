module.exports =
(function ($) {
    "use strict";
    var utils = require('./utils.js');

    return function(vector, angle) {
        this.vector = vector;
        this.angle = angle;

        this.getString = function () {

            if (utils.isFF()) {
                return '';
            }

            return 'rotate3d(' + this.vector.x + ', ' + this.vector.y + ', ' + this.vector.z + ', ' + this.angle + 'deg)';
        };
    };

})(jQuery);