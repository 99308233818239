module.exports =
(function ($) {
    "use strict";
    return function (carousel, settings) {

        var effect_base = require('./effect_base.js');
        var getBezier = require('./../bezier.js');
    
        var base = new effect_base(carousel, {

        });

    
        $.extend(this, base);
        $.extend(this.settings, settings);

        this.apply = function (elementTransform, element, value) {
            var root = this.carousel.path.rootValue();
            var distance = Math.abs(value - root);
            var absDist = this.carousel.options.distance * this.carousel.options.fadeAwayNumberOfConfigurableElements;

            var bezierT = 1 - Math.min(distance / absDist, 1);

            var points = this.carousel.options.fadeAwayBezierPoints;
            var opacity = getBezier(bezierT, points.p1, points.p2, points.p3, points.p4).y;
            element.$element.css({ opacity: opacity / 100 });
        };
    };

})(jQuery);