module.exports =
(function ($) {
    "use strict";
    var getBezier = require('./bezier.js');

    var bezierBoxCache = [];

    function getBezierBox(p1, cp1, cp2, p2) {
        var point = getBezier(0, p1, cp1, cp2, p2);
        var minX = point.x;
        var minY = point.y;
        var maxX = point.x;
        var maxY = point.y;

        for (var i = 0; i <= 20; i++) {
            var tempPoint = getBezier(i * 0.05, p1, cp1, cp2, p2);
            minX = Math.min(tempPoint.x, minX);
            minY = Math.min(tempPoint.y, minY);
            maxX = Math.max(tempPoint.x, maxX);
            maxY = Math.max(tempPoint.y, maxY);
        }

        return {
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY,
            width: maxX - minX,
            height: maxY - minY
        };
    };

    return function(p1, cp1, cp2, p2) {
        var key = p1.x + '.' + p1.y +
            '.' + cp1.x + '.' + cp1.y +
            '.' + cp2.x + '.' + cp2.y +
            '.' + p2.x + '.' + p2.y;

        if (typeof (bezierBoxCache[key]) == "undefined")
            bezierBoxCache[key] = getBezierBox(p1, cp1, cp2, p2);
        return bezierBoxCache[key];
    };

})(jQuery);
