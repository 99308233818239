module.exports =
(function ($) {
    "use strict";
    return function (carousel, settings) {

        var path_base = require('./path_base.js');
        var point = require('./../point.js');

        var base = new path_base(carousel, {
            wideness: 200,
        });

        $.extend(this, base);
        $.extend(this.settings, settings);

        this.getPointInternal = function (value) {
            var y = (value * value * value + value * 20) / (1000 * this.settings.wideness);
            var z = -2 * Math.abs(y);

            return new point(value, y, z);
        };
    };

})(jQuery);
