module.exports =
(function ($) {
    "use strict";
    return function (carousel) {

        this.autorotationStarted = false;
        this.carousel = carousel;
        this.autorotationTimer = null;

        extend(this);
        this.carousel.widget().on("motionEnd", this.onMotionEnd);
        this.applySettings();
    };

    function extend(obj) {
        obj.applySettings = function () {
            if (this.carousel.options.autorotation)
                this.ensureStarted();
            else {
                this.autorotationStarted = false;
                if (this.autorotationTimer)
                    clearTimeout(this.autorotationTimer);
            }
        };

        obj.ensureStarted = function() {
            if (!this.autorotationStarted) {
                this.autorotationStarted = !!(this.move());
            }
        };

        obj.move = $.proxy(function() {
            if (this.carousel.options.autorotationDirection == 'left') {
                return this.carousel.moveBack();
            }

            if (this.carousel.options.autorotationDirection == 'right') {
                return this.carousel.moveForward();
            }
        }, obj);

        obj.onMotionEnd = $.proxy(function () {
            if (this.autorotationStarted) {

                if (this.autorotationTimer)
                    clearTimeout(this.autorotationTimer);

                this.autorotationTimer = setTimeout(this.move, this.carousel.options.autorotationPause);
            }
        }, obj);

        obj.destroy = function () {
            this.carousel.widget().off("motionEnd", this.onMotionEnd);
        };
    }
})(jQuery);