var transform = require('./transform.js');


module.exports =
(function ($) {
    "use strict";
    return function (carousel) {

        this.carousel = carousel;
        extend(this);
    };

    function extend(obj) {

        obj.isFallbackMode = function () { return false; };

        obj.getActualDistance = function() {
            return this.carousel.options.distance;
        };

        obj.getRootValue = function() {
            return this.carousel.path.rootValue();
        };

        obj.incrementValue = function (value, increment) {
            return this.carousel.path.incrementValue(value, increment);
        };

        obj.decrementValue = function (value, decrement) {
            return this.carousel.path.decrementValue(value, decrement);
        };

        obj.minValue = function () {
            return this.carousel.path.minValue();
        };

        obj.maxValue = function () {
            return this.carousel.path.maxValue();
        };

        obj.moveTo = function (index) {
            if (this.carousel.motionController.motionInProgress())
                return;

            this.carousel.animation.clearQueue();
            this.carousel.animation.completeCurrentImmediately();

            if (index == this.carousel.options.selectedIndex)
                return;
            if (index == this.carousel.options.selectedIndex + 1) {
                this.carousel.moveForward();
                return;
            }
            if (index == this.carousel.options.selectedIndex - 1) {
                this.carousel.moveBack();
                return;
            }

            index = Math.max(0, index);
            index = Math.min(index, this.carousel.elements.length - 1);

            this.moveToInternal(index);
        };

        obj.moveToInternal = function (index) {
            var distance = this.getActualDistance() * (this.carousel.options.selectedIndex - index);
            this.carousel.inputController.nonInterruptibleMode(true);
            this.carousel._raiseMotionStart();
            this.carousel.animation.animate(0, distance, index, "linear");
        };

        obj.moveBack = function () {
            var pendingTarget = this.carousel.animation.isInProgress ? this.carousel.animation.getTargetValue() : this.carousel.options.selectedIndex;

            if (pendingTarget > 0) {
                pendingTarget--;

                this.carousel.inputController.nonInterruptibleMode(true);
                this.carousel._raiseMotionStart();
                this.carousel.animation.animate(0, this.getActualDistance(), pendingTarget, null);
                return true;
            }
            return false;
        };

        obj.moveForward = function () {
            var pendingTarget = this.carousel.animation.isInProgress ? this.carousel.animation.getTargetValue() : this.carousel.options.selectedIndex;

            if (pendingTarget < this.carousel.elements.length - 1) {
                pendingTarget++;

                this.carousel.inputController.nonInterruptibleMode(true);
                this.carousel._raiseMotionStart();
                this.carousel.animation.animate(0, -1 * this.getActualDistance(), pendingTarget, null);
                return true;
            }
            return false;
        };

        obj.consumeMotion = function (distance) {
            var highFrictionRange = this.carousel._alignElements(distance);

            var optDistance = this.getActualDistance();

            var scrolledElements = parseInt(distance / optDistance, 10);

            var prevIndex = this.carousel.options.selectedIndex;
            this.carousel.options.selectedIndex -= scrolledElements;

            this.carousel.options.selectedIndex = Math.max(0, this.carousel.options.selectedIndex);
            this.carousel.options.selectedIndex = Math.min(this.carousel.options.selectedIndex, this.carousel.elements.length - 1);

            if (prevIndex != this.carousel.options.selectedIndex)
                this.carousel._raiseChangeEvent();

            return { distance: (prevIndex - this.carousel.options.selectedIndex) * optDistance, highFrictionRange: highFrictionRange };
        };

        obj.handleMotionEnd = function (remainingDistance) {
            if (remainingDistance == 0)
                return;

            var targetIndex = this.carousel.options.selectedIndex;

            if (Math.abs(remainingDistance) > this.getActualDistance() / 2) {
                if (remainingDistance < 0)
                    targetIndex++;
                else
                    targetIndex--;
            }

            if (this.carousel.elements.length == 0)
                targetIndex = 0;
            else {
                targetIndex = Math.max(0, targetIndex);
                targetIndex = Math.min(targetIndex, this.carousel.elements.length - 1);
            }

            var targetDistance = (this.carousel.options.selectedIndex - targetIndex) * this.getActualDistance();

            var duration = Math.abs(this.carousel.options.rotationAnimationDuration * (remainingDistance / this.getActualDistance()));
            duration = Math.min(duration, this.carousel.options.rotationAnimationDuration / 2);

            this.carousel.animation.animate(remainingDistance, targetDistance, targetIndex, null, duration);
        };

        obj.alignElements = function (animationShift) {
            if (this.carousel.elements.length == 0 || this.carousel.options.selectedIndex < 0)
                return false;

            this.carousel.containerSize = this.carousel._getContainerSize();

            var shift = 0;
            if (typeof (animationShift) != "undefined")
                shift = animationShift;

            var highFrictionRange = false;
            // slow down at the ends
            if (
                (this.carousel.options.selectedIndex == 0 && shift > 0) ||
                (this.carousel.options.selectedIndex == this.carousel.elements.length - 1 && shift < 0)
            ) {
                shift = Math.pow(Math.abs(shift), 0.7) * (shift / Math.abs(shift));
                highFrictionRange = true;
            }

            var location = this.getRootValue();
            var rangeShift = 0;
            if ((this.carousel.options.selectedIndex == 0 && shift > 0) || (this.carousel.options.selectedIndex == this.carousel.elements.length - 1 && shift < 0))
                rangeShift = shift;
            var ranges = this.getFadeRanges(location + rangeShift);

            for (var i = this.carousel.options.selectedIndex; i < this.carousel.elements.length; i++) {
                this.setElementPosition(this.carousel.elements[i], location + shift, ranges);
                location = this.incrementValue(location, this.getActualDistance());
            }

            location = this.getRootValue();

            for (var i = this.carousel.options.selectedIndex - 1; i >= 0; i--) {
                location = this.decrementValue(location, this.getActualDistance());
                this.setElementPosition(this.carousel.elements[i], location + shift, ranges);
            }

            this.setZIndexes();

            return highFrictionRange;
        };

        obj.setElementPosition = function (element, value, ranges) {
            //this method is performance critical so we trying to avoid jQuery usage

            if (this.setElementVisibility(ranges, element, value)) {

                var point = this.carousel.path.getPoint(value);

                var elementTransform = new transform();

                elementTransform.translateZ = point.z * this.carousel.options.scaleZ;
                elementTransform.translateX = point.x * this.carousel.options.scaleX + this.carousel.containerSize.width / 2 - element.size.width / 2;
                elementTransform.translateY = point.y * this.carousel.options.scaleY + this.carousel.containerSize.height / 2 - element.size.height / 2;

                var pathRotation = this.carousel.path.elementsRotation();
                if (pathRotation)
                    elementTransform.rotations.push(pathRotation);

                if (this.carousel.options.mode3D == 'scale') {
                    elementTransform.scale = this.carousel.options.perspective / (this.carousel.options.perspective - elementTransform.translateZ);
                    elementTransform.translateZ = 0;
                }

                for (var i = 0; i < this.carousel.effects.length; i++) {
                    if (this.carousel.effects[i].applyPhase === 'positioning')
                        this.carousel.effects[i].apply(elementTransform, element, value);
                }

                elementTransform.apply(element, this.carousel.options.perspective,
                    this.carousel.containerSize.width, this.carousel.containerSize.height);
                element.location = point;
                return true;
            }
            return false;
        };

        obj.setZIndexes = function () {

            var tmpElements = [];
            for (var i = 0; i < this.carousel.elements.length; i++) {
                var e = this.carousel.elements[i];
                if (e.location) // element has been positioned
                    tmpElements.push(e);
            }

            tmpElements.sort(function (e1, e2) {
                return e1.location.z - e2.location.z;
            });
            for (var i = 0; i < tmpElements.length; i++) {
                tmpElements[i].$element.get(0).style.zIndex = i;
            }
        };

        obj.getFadeRanges = function (root) {
            var location = root;

            var res = [];

            if (this.carousel.options.numberOfElementsToDisplayLeft != null) {
                res.push({
                    from: this.decrementValue(location, this.getActualDistance() * (this.carousel.options.numberOfElementsToDisplayLeft + 1)),
                    to: this.decrementValue(location, this.getActualDistance() * (this.carousel.options.numberOfElementsToDisplayLeft)),
                    hide: 'before'
                });
            }

            if (this.carousel.options.numberOfElementsToDisplayRight != null) {
                res.push({
                    from: this.incrementValue(location, this.getActualDistance() * (this.carousel.options.numberOfElementsToDisplayRight)),
                    to: this.incrementValue(location, this.getActualDistance() * (this.carousel.options.numberOfElementsToDisplayRight + 1)),
                    hide: 'after'
                });
            }

            return res;
        };

        obj.setElementVisibility = function (fadeRanges, element, value) {
            var $element = element.$element;
            var hidden = false;

            if ((this.minValue() != null && value < this.minValue())
                || (this.maxValue() != null && value > this.maxValue()))
                hidden = true;
            else {

                if (fadeRanges.length == 0)
                    $element.css({ opacity: 1 });

                for (var i = 0; i < fadeRanges.length; i++) {
                    var range = fadeRanges[i];

                    if ((range.hide == 'before' && value <= range.from) || (range.hide == 'after' && value >= range.to)) {
                        hidden = true;
                        break;
                    }

                    if (value > range.from && value < range.to) {
                        var distance = range.to - range.from;
                        var passed = Math.abs(value - (range.hide == 'after' ? range.from : range.to));
                        var opacity = (distance - passed) / distance;
                        $element.css({ opacity: opacity });
                        break;
                    } else {
                        $element.css({ opacity: 1 });
                    }
                }
            }

            this.setElementVisibilityInternal(hidden, element);
            return !hidden;
        };

        obj.destroy = function () {
            for (var i = 0; i < this.carousel.elements.length; i++) {
                this.carousel.elements[i].$element.css({
                    left: '0',
                    top: '0',
                    transform: '',
                    opacity: '1'
                });
                this.carousel.elements[i].$element.show();
            }
        };

        obj.setElementVisibilityInternal = function (hide, element) {
            if (hide) {
                element.$element.hide();
            } else {
                element.$element.show();
            }
        };
    }

})(jQuery);