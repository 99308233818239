module.exports =
(function ($) {
    "use strict";
    var exports = null;

    function extend(obj) {

        obj.isFallbackMode = function () { return true; };

        obj.getActualDistance = function () {
            return this.carousel.options.distanceInFallbackMode;
        };

        obj.getRootValue = function () {
            return 0;
        };

        obj.incrementValue = function (value, increment) {
            return value + increment;
        };

        obj.decrementValue = function (value, decrement) {
            return value - decrement;
        };

        obj.minValue = function () {
            return -1 * this.maxValue();
        };

        obj.maxValue = function () {
            return (this.carousel.containerSize.width + this.getActualDistance()) / 2;
        };

        obj.setElementPosition = function (element, value) {

            var left = value - element.size.width / 2 + this.carousel.containerSize.width / 2;
            var top = this.carousel.containerSize.height / 2 - element.size.height / 2;

            element.$element.css({
                transform: '',
                left: left + 'px',
                top: top + 'px'
            });

            var visible = value > this.minValue() && value < this.maxValue()

            if (visible)
                element.$element.show();
            else
                element.$element.hide();

            return visible;
        };

        obj.destroy = function () {

            this.base.destroy();

            for (var i = 0; i < this.carousel.elements.length; i++) {
                this.carousel.elements[i].$element.css({
                    left: '0',
                    top: '0'
                });
            }
        };
    }

    function has3d() {
        if (document.body && document.body.style.perspective !== undefined) {
            return true;
        }
        var _tempDiv = document.createElement("div"),
            style = _tempDiv.style,
            a = ["Webkit", "Moz", "O", "Ms", "ms"],
            i = a.length;
        if (_tempDiv.style.perspective !== undefined) {
            return true;
        }
        while (--i > -1) {
            if (style[a[i] + "Perspective"] !== undefined) {
                return true;
            }
        }
        return false;
    }

    exports = function (carousel, baseRotator) {
        this.base = baseRotator;
        $.extend(this, this.base);
        extend(this);
    };

    exports.fallback = function () {
        return !has3d();
    };

    return exports;

})(jQuery);
