module.exports =
(function ($) {
    "use strict";
    return function (carousel, settings) {

        var path_base = require('./path_base.js');
        var point = require('./../point.js');

        var base = new path_base(carousel, {
            wideness: 200,
        });

        $.extend(this, base);
        $.extend(this.settings, settings);

        this.getPointInternal = function (value) {
            var z = -1 * value * value * (1 / this.settings.wideness);
            return new point(value, 0, z);
        };
    };

})(jQuery);