module.exports =
(function ($) {
    "use strict";
    return function (carousel, settings) {

        this.carousel = carousel;

        this.applyPhase = 'positioning';

        this.settings = {
        };

        $.extend(this.settings, settings);

        this.getApplyingPriority = function () {
            return 0;
        };

        this.apply = function (elementTransform, element, value) {

        };

        this.revert = function () {

        };
    };

})(jQuery);