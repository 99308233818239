module.exports =
(function ($) {
    "use strict";
    return function(carousel) {
        this.carousel = carousel;

        this.isInProgress = false;
        this.inProgressAnimationTarget = null;
        this.queue = [];
        this.distance = [];
        this.currentElement = null;

        this.clearQueue = function () {
            this.queue = [];
            this.distance = [];
        };

        this.animate = function (from, to, targetValue, easing, duration) {

            if (typeof (easing) == "undefined")
                easing = null;
            if (typeof (duration) == "undefined")
                duration = null;

            this.addDistance(Math.abs(to - from));

            if (this.queue.length > 5)
                return;

            this.queue.push({ from: from, to: to, targetValue: targetValue, easing: easing, duration: duration });

            if (!this.isInProgress) {
                this.peekFromQueue();
            }
        };

        this.completeCurrentImmediately = function () {
            if (this.currentElement != null) {
                this.currentElement.stop(true, true);
            }
        };

        this.peekFromQueue = function () {

            if (this.queue.length > 0) {
                var element = this.queue[0];
                this.queue = this.queue.slice(1);
                this.inProgressAnimationTarget = element.targetValue;
                this.currentElement = $(element);
                var stepDist = Math.abs(element.from - element.to);

                var easing = element.easing == null ? this.getEasing(stepDist) : element.easing;
                var duration = (element.duration == null ? this.carousel.options.rotationAnimationDuration : element.duration) * this.getDurationCoefficient(stepDist);

                this.currentElement.animate({ from: element.to }, {
                    easing: easing,
                    duration: duration,
                    start: $.proxy(this.onStart, this),
                    step: $.proxy(this.onStep, this),
                    done: $.proxy(this.onDone, this),
                    always: $.proxy(this.onAlways, this)
                });
            }
        };

        this.getTargetValue = function () {
            if (this.queue.length > 0)
                return this.queue[this.queue.length - 1].targetValue;
            return this.inProgressAnimationTarget;
        };

        this.onStart = function () {
            this.isInProgress = true;
        };

        this.onStep = function (val) {
            $(this).trigger('step', val);
        };

        this.onDone = function () {
            $(this).trigger('done', this.inProgressAnimationTarget);
        };

        this.onAlways = function () {
            this.isInProgress = false;
            this.peekFromQueue();
            this.currentElement = null;
        };

        this.addDistance = function (value) {
            this.distance.push({ date: new Date(), value: value });

            this.distance = $(this.distance).filter(function (i, d) {
                return (new Date() - d.date) < 5000;
            });
        };

        this.getActualDistance = function () {

            var distance = 0;
            var date = new Date();
            for (var i = 0; i < this.distance.length; i++) {

                var d = this.distance[i];

                if ((date - d.date) < this.carousel.options.rotationAnimationDuration)
                    distance += d.value;
            }

            return distance;
        };

        // adjust rotation duration, if user quiqly press next button several times
        this.getDurationCoefficient = function (oneStepDist) {
            if (this.carousel.options.autorotation) // adjustment is not required for auto rotation
                return 1; // 

            if (this.getActualDistance() == 0)
                return 1;
            return 1 / (this.getActualDistance() / oneStepDist);
        };

        this.getEasing = function (oneStepDist) {
            if (this.getDurationCoefficient(oneStepDist) > 0.4)
                return this.carousel.options.rotationAnimationEasing;
            else
                return "linear";
        };
    };

})(jQuery);
