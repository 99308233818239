function b1(t) { return t * t * t; };

function b2(t) { return 3 * t * t * (1 - t); };

function b3(t) { return 3 * t * (1 - t) * (1 - t); };

function b4(t) { return (1 - t) * (1 - t) * (1 - t); };

module.exports =
(function ($) {
    "use strict";
    return function(t, p1, cp1, cp2, p2) {
        var pos = {x:0,y:0};
        pos.x = p1.x*b1(t) + cp1.x*b2(t) + cp2.x*b3(t) + p2.x*b4(t);
        pos.y = p1.y*b1(t) + cp1.y*b2(t) + cp2.y*b3(t) + p2.y*b4(t);
        return pos;
    };

})(jQuery);
