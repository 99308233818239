require('./easings.js')(); // patch jquery with easings

/*!
 * TODO: add about
 * 
 */

(function ($) {
    "use strict";
    if (!$.theta) {
        $.theta = new Object();
    };

    var comulative_animation = require('./comulative_animation.js');
    var fluid_layout = require('./fluid_layout.js');
    var elements_size_updater = require('./elements_size_updater.js');
    var input_controller = require('./input_controller.js');
    var motion_controller = require('./motion_controller.js');
    var rotation_logic_controller = require('./rotation_logic_controller.js');
    var endless_rotation_logic_controller = require('./endless_rotation_logic_controller.js');
    var fallback_rotation_logic_controller = require('./fallback_rotation_logic_controller.js');
    var auto_rotator = require('./auto_rotator.js');
    var size = require('./size.js');
    var utils = require('./utils.js');

    var path_archimedes_spiral = require('./paths/path_archimedes_spiral.js');
    var path_cubic = require('./paths/path_cubic.js');
    var path_cubic_bezier = require('./paths/path_cubic_bezier.js');
    var path_ellipse = require('./paths/path_ellipse.js');
    var path_line = require('./paths/path_line.js');
    var path_parabola = require('./paths/path_parabola.js');
    
    var effect_allign_to_path = require('./VisualEffects/effect_allign_to_path.js');
    var effect_fade_away = require('./VisualEffects/effect_fade_away.js');
    var effect_pop_out_selected = require('./VisualEffects/effect_pop_out_selected.js');
    var effect_rotation = require('./VisualEffects/effect_rotation.js');
    var effect_shadow = require('./VisualEffects/effect_shadow.js');
    var effect_size_adjustment = require('./VisualEffects/effect_size_adjustment.js');
    var effect_reflection = require('./VisualEffects/effect_reflection.js');
    
    var version = '1.7.0';
    var defaultOptions = {
        filter: "div",
        selectedIndex: 0,
        distance: 70,
        mode3D: 'z',
        scaleX: 1,
        scaleY: 1,
        scaleZ: 1,
        perspective: 1000,
        numberOfElementsToDisplayRight: null,
        numberOfElementsToDisplayLeft: null,
        sensitivity: 1,
        verticalRotation: false,
        minKeyDownFrequency: 0,
        rotationAnimationEasing: 'easeOutCubic',
        rotationAnimationDuration: 500,
        inertiaFriction: 10,
        inertiaHighFriction: 50,
        path: {
            type: "parabola",
            settings: {}
        },
        designedForWidth: null,
        designedForHeight: null,
        enabled: true,
        mousewheelEnabled: true,
        keyboardEnabled: true,
        gesturesEnabled: true,

        autorotation: false,
        autorotationDirection: 'right', /* left, right */
        autorotationPause: 0,

        // effects
        allignElementsWithPath: false,
        allignElementsWithPathCoefficient: 1,

        fadeAway: false,
        fadeAwayNumberOfConfigurableElements: 5,
        fadeAwayBezierPoints: { p1: { x: 0, y: 100 }, p2: { x: 50, y: 50 }, p3: { x: 50, y: 50 }, p4: { x: 100, y: 0 } },

        rotation: false,
        rotationVectorX: 0,
        rotationVectorY: 0,
        rotationVectorZ: 0,
        rotationNumberOfConfigurableElements: 5,
        rotationBezierPoints: { p1: { x: 0, y: 0 }, p2: { x: 50, y: 0 }, p3: { x: 50, y: 0 }, p4: { x: 100, y: 0 } },
        rotationInvertForNegative: false,

        sizeAdjustment: false,
        sizeAdjustmentNumberOfConfigurableElements: 5,
        sizeAdjustmentBezierPoints: { p1: { x: 0, y: 100 }, p2: { x: 50, y: 100 }, p3: { x: 50, y: 100 }, p4: { x: 100, y: 100 } },

        shadow: false,
        shadowBlurRadius: 100,
        shadowSpreadRadius: 0,
        shadowSelector: null,

        popoutSelected: false,
        popoutSelectedShiftX: 0,
        popoutSelectedShiftY: 0,
        popoutSelectedShiftZ: 0,

        reflection: false,
        reflectionBelow: 0,
        reflectionHeight: 0.3,

        fallback: 'auto', // auto, always, never
        distanceInFallbackMode: 200
    };

    $.theta.carousel = function (domElement, options) {

        var carousel = this;
        carousel.$element = $(domElement);
        carousel.$element.data("theta.carousel", carousel);
        carousel.$element.addClass('theta-carousel');

        carousel._create = function () {
            this.options = $.extend(true, {}, $.theta.carousel.defaultOptions, options);

            // prepare container
            var containerSize = new size(this.widget().width(), this.widget().height());

            this.container = $('<div class="theta-carousel-inner-container"></div>');
            this.container.appendTo(this.widget());
            this.container.css({
                width: containerSize.width + 'px',
                height: containerSize.height + 'px'
            });

            this.widget().attr('tabindex', 0).css({ outline: 'none', overflow: 'hidden' });
            this.container.css({
                overflow: 'hidden',
                transform: 'translate3d(0px,0px, 100000px)'
            });

            if (!utils.isFF()) {
                // bugs in firefox
                this.container.css({
                    perspective: this.options.perspective + 'px',
                });
            }

            // init elements
            this.update();

            // prepare widget
            this.effects = [];
            this._createPath();
            this._createEffects();
            this._createRotationLogicController();
            this.elementsSizeUpdater = new elements_size_updater(this);
            this.fluidLayout = new fluid_layout(this);
            this._alignElements();
            this.animation = new comulative_animation(this);
            this.motionController = new motion_controller(this, $.proxy(this._motionConsumer, this));
            this.inputController = new input_controller(this);
            this.autoRotator = new auto_rotator(this);

            // attach event listeners
            $(this.animation).on('step', $.proxy(function (e, shift) { this._alignElements(shift); }, this));
            $(this.animation).on('done', $.proxy(function (e, value) {
                this._rotationAnimationCompleted(value);
                this._raiseMotionEnd();
            }, this));
            $(this.motionController).on('end', $.proxy(function (e, value) { this._motionEnd(value); }, this));
            $(this.motionController).on('start', $.proxy(this._raiseMotionStart, this));

            this.initialized = true;
        };

        carousel.destroy = function () {

            for (var i = 0; i < this.effects.length; i++) {
                this.effects[i].revert();
            }

            if (this.rotationLogicController != null)
                this.rotationLogicController.destroy();
            this.inputController.destroy();
            this.fluidLayout.destroy();
            this.elementsSizeUpdater.destroy();
            this.autoRotator.destroy();
            for (var i = 0; i < this.elements.length; i++) {
                this.elements[i].$element.off('tap', this.moveTo);
                this.elements[i].$element.off("click", this.moveTo);
            }
            this.widget().data('theta.carousel', null);
        };

        carousel.moveBack = function () {
            return this.rotationLogicController.moveBack();
        };

        carousel.moveForward = function () {
            return this.rotationLogicController.moveForward();
        };

        carousel.moveTo = function (index) {
            this.rotationLogicController.moveTo(index);
        };

        carousel.invalidate = function () {
            if (!this._isInMotion)
                this._alignElements();
        };

        carousel.update = function () {

            var itemsToAdd = this.widget().contents().filter(
                function() {
                    return (
                        this.nodeType == 8 /*we need comments for AngularJS*/
                        ||
                        !$(this).hasClass('theta-ignore')
                    ) && !$(this).hasClass('theta-carousel-inner-container'); // ignore internal container
                });

            itemsToAdd.appendTo(this.container);

            this.elements = this.container.children().filter(this.options.filter).map(function (i, e) {
                var $e = $(e);

                var order = $e.data('theta-order');

                if (!order)
                    order = i;
                
                return { $element: $e, element: e, order: order };
            }).toArray();

            this.elements.sort(function (e1, e2) { return e1.order - e2.order; });

            for (var i = 0; i < this.elements.length; i++) {

                this.elements[i].index = i;
                this.elements[i].element.index = i;

                if (!this.elements[i].$element.hasClass('theta-carousel-element')) {

                    var moveToProxy = $.proxy(function (e) {
                        if (this.options.enabled && !this.options.autorotation) {
                            this.moveTo(e.index);
                        }
                    }, this, this.elements[i].element);

                    this.elements[i].$element.addClass('theta-carousel-element');
                    this.elements[i].$element.css({ position: 'absolute' });
                    this.elements[i].$element.on('tap', moveToProxy);
                    this.elements[i].$element.click(moveToProxy);

                }
            }

            this.options.selectedIndex = Math.max(Math.min(this.options.selectedIndex, this.elements.length - 1), 0);

            if (this.elements.length == 0)
                this.options.selectedIndex = -1;

            if (this.initialized) {
                this.elementsSizeUpdater.update();
                this.invalidate();
            }

            this._applyCurrentItemStyle();
        };

        carousel.getIsInMotion = function () {
            return this._isInMotion;
        };

        carousel.getIsFallbackMode = function () {
            return this.rotationLogicController.isFallbackMode();
        };

        carousel.widget = function () {
            return this.$element;
        };

        carousel._setOption = function (name, value) {
            
            utils.setObjectPropertyValue(carousel.options, name, value);

            if (name === 'rotationAnimationDuration' || name === 'rotationAnimationEasing') {
                // don't need to do something if these properties has been changed
                return;
            }

            if (name === 'filter') {
                this.update();
            }

            if (name === 'perspective') {
                this.container.css({ perspective: value + 'px' });
                if (this.options.mode3D == 'scale')
                    this._alignElements();
            }

            if (name.indexOf('path') == 0 || name === 'fallback') {
                this._createPath();
                this._createRotationLogicController();
                this._alignElements();
            }

            if (name === "selectedIndex" || name === "distance" || name === "mode3D"
                || name === "numberOfElementsToDisplayRight" || name === "numberOfElementsToDisplayLeft"
                || name === "scaleX" || name === "scaleY" || name === "scaleZ"
                || name === "allignElementsWithPathCoefficient"
                || name === "fadeAwayBezierPoints" || name === "fadeAwayNumberOfConfigurableElements"
                || name === "rotationBezierPoints" || name === "rotationNumberOfConfigurableElements" || name === "rotationInvertForNegative"
                || name === "rotationVectorX" || name === "rotationVectorY" || name === "rotationVectorZ"
                || name === "sizeAdjustmentNumberOfConfigurableElements" || name === "sizeAdjustmentBezierPoints"
                || name === "shadowBlurRadius" || name === "shadowSpreadRadius"
                || name === "popoutSelectedShiftX" || name === "popoutSelectedShiftY" || name === "popoutSelectedShiftZ"
                || name === "distanceInFallbackMode"
                ) {
                this._alignElements();
            }

            if (name.indexOf('autorotation') != -1) {
                this.autoRotator.applySettings();
            }

            if (name.indexOf('allignElementsWithPath') != -1 || name.indexOf('fadeAway') != -1 || name.indexOf('rotation') != -1
                || name.indexOf('sizeAdjustment') != -1 || name.indexOf('shadow') != -1 || name.indexOf('popoutSelected') != -1
                || name.indexOf('reflection') != -1) {
                this._createEffects();
                this._alignElements();
            }

            if (name === 'selectedIndex') {
                this._applyCurrentItemStyle();
            }
        };

        carousel._createRotationLogicController = function()
        {
            if (this.rotationLogicController != null)
                this.rotationLogicController.destroy();

            if (this.path.isEndless())
                this.rotationLogicController = new endless_rotation_logic_controller(this);
            else
                this.rotationLogicController = new rotation_logic_controller(this);

            if (this.options.fallback == 'always' || (this.options.fallback == 'auto' && fallback_rotation_logic_controller.fallback())) {
                this.rotationLogicController = new fallback_rotation_logic_controller(this, this.rotationLogicController);
            }

            if (this.autoRotator) {
                this.autoRotator.applySettings();
            }
        };

        carousel._createEffects = function () {

            for (var i = 0; i < this.effects.length; i++) {
                this.effects[i].revert();
            }

            this.effects = [];

            if (this.options.allignElementsWithPath)
                this.effects.push(new effect_allign_to_path(this, {}));

            if (this.options.fadeAway)
                this.effects.push(new effect_fade_away(this, {}));

            if (this.options.rotation)
                this.effects.push(new effect_rotation(this, {}));

            if (this.options.sizeAdjustment)
                this.effects.push(new effect_size_adjustment(this, {}));

            if (this.options.shadow)
                this.effects.push(new effect_shadow(this, {}));

            if (this.options.popoutSelected)
                this.effects.push(new effect_pop_out_selected(this, {}));

            if (this.options.reflection)
                this.effects.push(new effect_reflection(this, {}));
        };

        carousel._createPath = function () {
            var newPath = null;

            if (this.options.path.type == "parabola") {
                newPath = new path_parabola(this, this.options.path.settings);
            }

            if (this.options.path.type == "line") {
                newPath = new path_line(this, this.options.path.settings);
            }

            if (this.options.path.type == "cubic") {
                newPath = new path_cubic(this, this.options.path.settings);
            }

            if (this.options.path.type == "archimedes_spiral") {
                newPath = new path_archimedes_spiral(this, this.options.path.settings);
            }

            if (this.options.path.type == "ellipse") {
                newPath = new path_ellipse(this, this.options.path.settings);
            }

            if (this.options.path.type == "cubic_bezier") {
                newPath = new path_cubic_bezier(this, this.options.path.settings);
            }

            if (newPath != null) {
                this.path = newPath;
                this.options.path.settings = this.path.settings;
            } else
                throw "path " + this.options.path.type + " is not supported.";
        };

        carousel._raiseChangeEvent = function () {
            this.widget().trigger("change", { index: this.options.selectedIndex });
            this._applyCurrentItemStyle();
        };

        carousel._applyCurrentItemStyle = function () {
            for (var i = 0; i < this.elements.length; i++) {
                if (i === this.options.selectedIndex) {
                    this.elements[i].$element.addClass('theta-current-item');
                }
                else {
                    this.elements[i].$element.removeClass('theta-current-item');
                }
            }
        };

        carousel._raiseMotionStart = function () {
            this._isInMotion = true;
            this.widget().addClass('theta-in-motion');
            this.widget().trigger("motionStart", { index: this.options.selectedIndex });
        };

        carousel._raiseMotionEnd = function () {
            this.inputController.nonInterruptibleMode(false);
            this.widget().removeClass('theta-in-motion');
            this._isInMotion = false;
            this.widget().trigger("motionEnd", { index: this.options.selectedIndex });
        };

        carousel._rotationAnimationCompleted = function (index) {
            if (this.options.selectedIndex != index) {
                this.options.selectedIndex = index;
                this._raiseChangeEvent();
            }
            this._alignElements();
        };

        carousel._motionConsumer = function (distance) {
            return this.rotationLogicController.consumeMotion(distance);
        };

        carousel._motionEnd = function (remainingDistance) {
            this.rotationLogicController.handleMotionEnd(remainingDistance);
        };

        carousel._alignElements = function (animationShift) {
            return this.rotationLogicController.alignElements(animationShift);
        };

        carousel._getContainerSize = function () {
            var container = $('.theta-carousel-inner-container', this.widget());
            return new size(container.width(), container.height());
        };

        carousel._create();
    };

    $.theta.carousel.defaultOptions = defaultOptions;
    $.theta.carousel.version = version;

    $.fn.theta_carousel = function (options) {
        var callArguments = arguments;

        var hasCallRes = false;
        var callRes = null;

        var eachRes = this.each(function () {
            var $el = $(this);
            var instance = $el.data('theta.carousel');

            if (instance) {
                if (typeof options === 'string') {

                    if (typeof instance[options] === 'function') {
                        var args = Array.prototype.slice.call(callArguments, 1);
                        hasCallRes = true;
                        callRes = instance[options].apply(instance, args);
                    }

                    if (options == 'option') {
                        if (callArguments.length == 2) {
                            hasCallRes = true;
                            callRes = utils.getObjectPropertyValue(instance.options, callArguments[1]);
                        }

                        if (callArguments.length == 3) {
                            instance._setOption(callArguments[1], callArguments[2]);
                        }
                    }
                    
                } else {
                    var clone = $.extend(true, {}, options);
                    $.each(clone, $.proxy($el.data('theta.carousel')._setOption, $el.data('theta.carousel')));
                }
            }
            else 
                (new $.theta.carousel(this, options));
        });

        if (!hasCallRes)
            return eachRes;
        else
            return callRes;
    };

})(jQuery);