module.exports =
(function ($) {
    "use strict";
    return function (carousel, settings) {

        var effect_base = require('./effect_base.js');

        var base = new effect_base(carousel, {

        });

        $.extend(this, base);
        $.extend(this.settings, settings);

        this.apply = function (elementTransform, element, value) {
            var distFromRoot = Math.abs(value - this.carousel.path.rootValue());
            var coef = 1 - distFromRoot / this.carousel.options.distance;
            coef = Math.max(coef, 0);

            elementTransform.translateX += this.carousel.options.popoutSelectedShiftX * coef;
            elementTransform.translateY += this.carousel.options.popoutSelectedShiftY * coef;
            elementTransform.translateZ += this.carousel.options.popoutSelectedShiftZ * coef;
        };
    };

})(jQuery);