module.exports =
(function ($) {
    "use strict";
    return function (carousel) {
        this.carousel = carousel;
        this.movements = [];
        this.passedBrakingDistance = 0;
        this.isInProgress = false;
        this.lowFrictionInProgress = false;
        this.switchingToHighFriction = false;

        this.registerMovement = function (distance) {
            this.movements.push({ date: new Date(), distance: distance });
            this.clearOldMovements();
        };

        this.stop = function () {
            this.switchingToHighFriction = false;
            $(this).stop(false, false);
        };

        this.movedIntoHighFrictionRange = function () {
            if (this.lowFrictionInProgress) {
                this.lowFrictionInProgress = false;
                this.switchingToHighFriction = true;
                $(this).stop(false, false);

                var brakingDistLeft = this.motionData.fromPosition + this.motionData.brakingDistance - this.passedBrakingDistance;
                var speedLeft = this.motionData.initialSpeed * Math.abs(brakingDistLeft / this.motionData.brakingDistance);

                var friction = this.carousel.options.inertiaHighFriction * 100;
                var brakingDistance = (speedLeft * speedLeft) / (2 * friction);
                if (speedLeft < 0)
                    brakingDistance *= -1;

                var brakingTime = brakingDistance / (speedLeft / 2);

                $(this).animate({ passedBrakingDistance: this.passedBrakingDistance + brakingDistance }, {
                    easing: 'easeOutQuad',
                    duration: brakingTime * 1000,
                    step: $.proxy(this.onStep, this),
                    complete: $.proxy(this.onComplete, this),
                    fail: $.proxy(this.onFail, this)
                });
            }
        };

        this.run = function (fromPosition) {

            var speed = this.getSpeed();
            this.movements = [];

            if (speed == 0) {
                $(this).trigger('complete');
                return;
            }

            var friction = this.carousel.options.inertiaFriction * 100;
            var brakingDistance = (speed * speed) / (2 * friction);
            if (speed < 0)
                brakingDistance *= -1;

            var brakingTime = brakingDistance / (speed / 2);

            this.passedBrakingDistance = fromPosition;

            this.isInProgress = true;
            this.lowFrictionInProgress = true;
            this.motionData = {
                initialSpeed: speed,
                brakingDistance: brakingDistance,
                fromPosition: fromPosition
            };

            $(this).animate({ passedBrakingDistance: fromPosition + brakingDistance }, {
                easing: 'easeOutCirc',
                duration: brakingTime * 1000,
                step: $.proxy(this.onStep, this),
                complete: $.proxy(this.onComplete, this),
                fail: $.proxy(this.onFail, this)
            });
        };

        this.onStep = function (val) {
            if (isNaN(val))
                return; //for some easings we can get NaNs

            this.passedBrakingDistance = val;
            $(this).trigger('step', val);
        };

        this.onComplete = function () {
            this.isInProgress = false;
            $(this).trigger('complete');
        };

        this.onFail = function () {
            if (!this.switchingToHighFriction) {
                this.isInProgress = false;
                $(this).trigger('stop');
            }
        };

        this.clearOldMovements = function () {
            this.movements = $(this.movements).filter(function (i, d) {
                return (new Date() - d.date) < 5000;
            });
        };

        this.getSpeed = function () {

            var distance = 0;
            var date = new Date();
            for (var i = 0; i < this.movements.length; i++) {

                var d = this.movements[i];

                if ((date - d.date) < 200)
                    distance += d.distance;
            }

            return distance * 5;
        };
    };

})(jQuery);