module.exports = 
(function ($) {
    "use strict";
    return function (carousel, settings) {

        var path_base = require('./path_base.js');
        var point = require('./../point.js');

        var base = new path_base(carousel, {
            fi: 10,
            flatness: 10
        });

        $.extend(this, base);
        $.extend(this.settings, settings);

        this.getPointInternal = function (value) {

            var angle = value * Math.PI * 2 / 360;

            var x = this.settings.fi * value * Math.cos(angle) / (2 * Math.PI);
            var y = this.settings.fi * value * Math.sin(angle) / (2 * Math.PI);

            var z = -1 * Math.pow(value - this.rootValue(), 2) / this.settings.flatness;

            return new point(x, y, z);
        };

        this.rootValue = function () {
            return 450;
        };

        this.minValue = function () {
            return 10;
        };

        this.maxValue = function () {
            return 650;
        };
    };

})(jQuery);
