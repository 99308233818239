// we need to calculate 
var coefCache = [];
coefCache['X'] = { persp: 0, width: 0, val: 0 };
coefCache['Y'] = { persp: 0, width: 0, val: 0 };

module.exports =
(function ($) {
    "use strict";
    return function() {
        this.translateX = 0;
        this.translateY = 0;
        this.translateZ = 0;
        this.scale = 1;

        this.scaleX = 1;
        this.scaleY = 1;
        this.scaleZ = 1;

        this.rotations = [];

        this.calculateShiftCoeficient = function (perspective, width, z, coefDimension) {

            var cache = coefCache[coefDimension];

            if (perspective == 0) {
                return 1;
            }

            var tan = 0;
            var alpha = 0
            width = width / 2;

            if (cache.persp == perspective && cache.width == width) {
                tan = cache.val; // optimisation
            }
            else {
                var c = Math.sqrt(perspective * perspective + width * width);
                alpha = Math.asin(width / c);
                tan = (Math.tan(1.5708 - alpha));

                cache.persp = perspective;
                cache.width = width;
                cache.val = tan;
            }

            var b = -1 * (1 / tan * z);
            var resultingWidth = width + b;
            return width / resultingWidth;
        };

        this.apply = function (e, perspective, width, height) {

            var pseudo3dScale = (perspective / (perspective - this.translateZ));

            var itemWidth = e.size.width;
            var itemHeight = e.size.height;

            var coefX = this.calculateShiftCoeficient(perspective, width, this.translateZ, 'X');
            var coefY = this.calculateShiftCoeficient(perspective, height, this.translateZ, 'Y');

            var x = this.translateX + itemWidth / 2;
            var y = this.translateY + itemHeight / 2;
            x = x - width / 2;
            y = y - height / 2;

            x = x * coefX;
            y = y * coefY;

            x = x + (width / 2) - (itemWidth / 2);
            y = y + (height / 2) - (itemHeight / 2);

            var str = 'translate3d(' + x + 'px, ' + y + 'px, 0px)';
            if (this.scale != 1)
                str += ' scale(' + this.scale + ',' + this.scale + ')';

            for (var i = 0; i < this.rotations.length; i++)
                str += ' ' + this.rotations[i].getString();

            str += ' scale(' + pseudo3dScale + ', ' + pseudo3dScale + ')';

            if (this.scaleX != 1)
                str += ' scaleX(' + this.scaleX + ')';
            if (this.scaleY != 1)
                str += ' scaleY(' + this.scaleY + ')';
            if (this.scaleZ != 1)
                str += ' scaleZ(' + this.scaleZ + ')';

            e.element.style.transform = str;
            e.element.style.webkitTransform = str;
            e.element.style.msTransform = str;
        };
    };

})(jQuery);